import requestService from "../requestService";
import store from '@/store';

export default {
	async createRoute(form) {
		const response = await requestService.post(`/admin/route`, form, {
			headers: { Authorization: `Bearer ${store.getters.loggedUser.token}` }
		})
		return response?.data
	},
	async createPopularRoute(form) {
		const response = await requestService.post(`/admin/popular_route`, form, {
			headers: { Authorization: `Bearer ${store.getters.loggedUser.token}` }
		})
		return response?.data
	},
	async updateRoute(id, form) {
		const response = await requestService.post(`/admin/route/${id}`, form, {
			headers: { Authorization: `Bearer ${store.getters.loggedUser.token}` }
		})
		return response?.data
	},
	async searchRoute(slug) {
		const response = await requestService.get(`/admin/route/search/${slug}`, {
			headers: { Authorization: `Bearer ${store.getters.loggedUser.token}` }
		})
		return response?.data
	},
	async getRoute(id) {
		const response = await requestService.get(`/route/${id}`)
		return response?.data
	},
	async getRouteBySlug(slug) {
		const response = await requestService.get(`/route/${slug}`)
		return response?.data
	},
	async getRouteForAdmin(page) {
		const response = await requestService.get(`/admin/route?page=${page}`, {
			headers: { Authorization: `Bearer ${store.getters.loggedUser.token}` }
		})
		return response?.data
	},
	async getSymlinkForAdmin() {
		const response = await requestService.get(`/admin/popular_route/symlink`, {
			headers: { Authorization: `Bearer ${store.getters.loggedUser.token}` }
		})
		return response?.data
	},
	async getRoutes(page) {
		const response = await requestService.get(`/route?page=${page}`)
		return response?.data
	},
	async getRoutesForMain() {
		const response = await requestService.get(`/route/carousel/6`)
		return response?.data
	},
	async getRoutesSymlink(page) {
		const response = await requestService.get(`/route/symlink?page=${page}`)
		return response?.data
	},
	async getRoutesSymlinkByCountry(iso2code) {
		const response = await requestService.get(`${iso2code ? '/route/country/symlink/' + iso2code : '/route/symlink'} `)
		return response?.data
	},
	async getRoutesSymlinkById(id) {
		const response = await requestService.get(`/route/symlink/${id}`)
		return response?.data
	},
	async getPopularRoutes() {
		const response = await requestService.get(`/route/popular_symlink`)
		return response?.data
	},
	async deleteRoute(uuid) {
		const response = await requestService.delete(`/admin/route/${uuid}`, {
			headers: { Authorization: `Bearer ${store.getters.loggedUser.token}` }
		})
		return response?.data
	},
	async deletePopularRoute(id) {
		const response = await requestService.delete(`/admin/popular_route/${id}`, {
			headers: { Authorization: `Bearer ${store.getters.loggedUser.token}` }
		})
		return response?.data
	},
	async getPrice(id) {
		const response = await requestService.get(`/admin/route/price/${id}`, {
			headers: { Authorization: `Bearer ${store.getters.loggedUser.token}` }
		})
		return response?.data
	},
	async savePrices(id, form) {
		const response = await requestService.post(`/admin/route/price/${id}`, form, {
			headers: { Authorization: `Bearer ${store.getters.loggedUser.token}` }
		})
		return response?.data
	},
}