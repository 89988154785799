<template>
  <v-main>
    <v-container>
      <h1 class="popularRoutesTitle">Популярні рейси</h1>
      <v-row no-gutters align="center" style="margin-top: 48px">
        <v-col
          cols="12"
          xl="3"
          lg="3"
          md="3"
          sm="12"
          v-for="route in popularRoutes"
          :key="route.id"
          style="text-align: left; padding-right: 20px; margin-bottom: 20px"
          class="pointer"
          @click="
            $router.push(
              `/routes/${route?.departure_info?.departure_name}/${route?.arrive_info?.arrive_name}/${route.route.slug}/${route.symlink_id}`
            )
          "
          @mousemove="hoverItem = route.id"
          v-show="$vuetify.breakpoint.smAndDown"
        >
          <v-row no-gutters class="popularRoutesBackground">
            <v-icon style="margin-right: 12px">mdi-chevron-right</v-icon>
            <v-col class="py-0 px-0">
              <span style="z-index: 100">
                {{ route?.departure_info?.departure_name }} -
                {{ route?.arrive_info?.arrive_name }}</span
              >
            </v-col>
          </v-row>
        </v-col>
        <v-col
          v-show="!$vuetify.breakpoint.smAndDown"
          cols="12"
          xl="3"
          lg="3"
          md="3"
          sm="12"
          v-for="route in popularRoutes"
          :key="route.id"
          style="
            text-align: left;
            padding-right: 20px;
            user-select: none;
            margin-bottom: 20px;
          "
          class="pointer"
          @click="
            $router.push(
              `/routes/${route?.departure_info?.departure_name}/${route?.arrive_info?.arrive_name}/${route.route.slug}/${route.symlink_id}`
            )
          "
          @mousemove="hoverItem = route.id"
        >
          <v-row no-gutters class="popularRoutesBackground" align="center">
            <div style="width: 15%">
              <v-icon>mdi-chevron-right</v-icon>
            </div>
            <div style="width: 85%" class="text">
              <span style="z-index: 100">
                {{ route?.departure_info?.departure_name }} -
                {{ route?.arrive_info?.arrive_name }}</span
              >
            </div>
          </v-row>
        </v-col>
      </v-row>
      <v-row no-gutters justify="center" style="margin-top: 48px">
        <v-btn class="showAllBtn" @click="$router.push('/routes')"
          >Дивитися всі
          <div class="arrowRight"
        /></v-btn>
      </v-row>
    </v-container>
  </v-main>
</template>

<script>
import routesService from "@/requests/admin/routesService";
export default {
  data: () => ({
    popularRoutes: [],
    showItems: 8,
    isShowDetail: false,
  }),
  mounted() {
    this.getPopularRoutes();
  },
  methods: {
    async getPopularRoutes() {
      await routesService
        .getPopularRoutes()
        .then((res) => {
          if (res.status == "Success") {
            res.data.symlink.forEach((item) => {
              this.popularRoutes.push({
                ...item,
                route: res.data.routes.filter(
                  (route) => route.id == item.route_id
                )?.[0],
              });
            });
          }
        })
        .catch(() => {
          this.showLoader = false;
        });
      this.showLoader = false;
    },
  },
};
</script>

<style scoped>
.showAllBtn {
  text-transform: none;
  height: 40px;
  width: 280px;
  padding: 12px;
  text-align: center;
  border-radius: 5px;
  border: 1px solid #e1e1e1;
  color: #085895 !important;
  background-color: transparent !important;
}
@media only screen and (max-width: 1023px) {
  .popularRoutesTitle {
    color: #1b1b1b;
    text-align: center;
    font-family: Roboto;
    font-size: 24px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    text-align: center;
  }
}
@media only screen and (min-width: 1024px) {
  .popularRoutesTitle {
    color: #1b1b1b;
    text-align: center;
    font-family: Roboto;
    font-size: 32px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    text-align: center;
  }
}

.popularRoutesBackground {
  min-width: 280px;
  padding: 9px 8px;
  border-bottom: 1px solid rgba(218, 218, 218, 0.5);
  position: relative;
  overflow: hidden;
}

.popularRoutesBackground::after {
  content: "";
  position: absolute;
  top: 0;
  width: 0;
  height: 100%;
  color: #eeeeee;
  background-color: #085895;
  transition: all 0.5s ease-in;
}

.popularRoutesBackground::after {
  right: 0;
}
.popularRoutesBackground:hover::after {
  width: 100%;
}
.popularRoutesBackground:hover .text {
  color: #eeeeee !important;
  z-index: 20;
}
.popularRoutesBackground:hover {
  color: #eeeeee !important;
}
.popularRoutesBackground:hover .v-icon.mdi-chevron-right {
  color: #eeeeee !important;
  z-index: 100;
}
.arrowRight {
  width: 25px;
  height: 12px;
  margin-left: 12px;
  background-color: #085895;
  -webkit-mask: url("../../../assets/img/iconsSvg/arrowRightIcon.svg") no-repeat
    center;
  mask: url("../../../assets/img/iconsSvg/arrowRightIcon.svg") no-repeat center;
}
</style>