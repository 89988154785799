<template>
  <div style="text-align: left">
    <Header
      :color="'transparent'"
      :isMain="true"
      style="position: absolute; width: 100%; z-index: 30"
      class="header"
    />
    <div
      v-if="!$vuetify.breakpoint.smAndDown"
      class="px-0 py-0"
      style="text-align: center; position: absolute; width: 100%; z-index: 20"
      :style="
        $vuetify.breakpoint.smAndDown
          ? ' margin-top: 78px;'
          : ' margin-top: 168px;'
      "
    >
      <h1
        class="mainText"
        :style="
          $vuetify.breakpoint.xs
            ? 'padding-top: 20px;font-size: 24px; text-align:left;'
            : 'font-size: 40px;'
        "
      >
        <v-row no-gutters align="center" justify="center">
          <span
            >Міжнародні автобусні перевезення
            <span
              v-if="$vuetify.breakpoint.smAndDown"
              style="
                border-radius: 2px;
                background: #fed500;
                color: #144fa9;
                width: max-content;
                padding: 0px 4px;
              "
            >
              MBUS
            </span></span
          >
          <div
            v-if="!$vuetify.breakpoint.smAndDown"
            style="
              border-radius: 2px;
              background: #fed500;
              color: #144fa9;
              width: max-content;
              padding: 0px 4px;
            "
          >
            MBUS
          </div>
        </v-row>
      </h1>
      <h2 class="subtitle">
        Пасажирські перевезення із України в країни Європи
      </h2>
    </div>
    <v-main>
      <v-container :style="$vuetify.breakpoint.smAndDown ? '' : 'padding: 0px'">
        <div
          v-if="$vuetify.breakpoint.smAndDown"
          class="px-0 py-0"
          style="
            text-align: center;
            position: absolute;
            width: 100%;
            z-index: 20;
          "
          :style="
            $vuetify.breakpoint.smAndDown
              ? ' margin-top: 78px;'
              : ' margin-top: 168px;'
          "
        >
          <h1
            class="mainText"
            :style="
              $vuetify.breakpoint.xs
                ? 'padding-top: 20px;font-size: 24px; text-align:left;'
                : 'font-size: 40px;'
            "
          >
            <v-row no-gutters align="center" justify="center">
              <span
                >Міжнародні автобусні перевезення
                <span
                  v-if="$vuetify.breakpoint.smAndDown"
                  style="
                    border-radius: 2px;
                    background: #fed500;
                    color: #144fa9;
                    width: max-content;
                    padding: 0px 4px;
                  "
                >
                  MBUS
                </span></span
              >
              <div
                v-if="!$vuetify.breakpoint.smAndDown"
                style="
                  border-radius: 2px;
                  background: #fed500;
                  color: #144fa9;
                  width: max-content;
                  padding: 0px 4px;
                "
              >
                MBUS
              </div>
            </v-row>
          </h1>
          <v-row no-gutters justify="center">
            <h2 class="subtitle">
              Пасажирські перевезення із України в країни Європи
            </h2>
          </v-row>
        </div>
        <v-row no-gutters align="end">
          <img
            src="@/assets/img/mainBackground.png"
            width="420px"
            height="272px"
            alt="main background"
            style="margin-top: 40px"
            v-if="!$vuetify.breakpoint.smAndDown"
          />
          <img
            src="@/assets/img/mainBackgroundMobile.png"
            width="420px"
            height="222px"
            alt="main background"
            style="margin-top: 40px"
            v-if="$vuetify.breakpoint.smAndDown"
          />
          <v-row
            no-gutters
            align="end"
            justify="end"
            v-if="!$vuetify.breakpoint.smAndDown"
          >
            <img
              src="@/assets/img/people.png"
              width="180px"
              height="230px"
              alt="people"
              style="position: absolute; top: 120px; z-index: 10"
            />
          </v-row>
        </v-row>
        <div class="searchRouteBackground">
          <div v-if="loader" class="d-flex align-center mt-3">
            <v-progress-circular
              class="mx-auto"
              indeterminate
              color="#085895"
            ></v-progress-circular>
          </div>
          <search-routes-field v-show="!loader" />
        </div>
        <v-row
          v-if="!$vuetify.breakpoint.smAndDown"
          no-gutters
          align="center"
          justify="center"
          style="margin-top: 50px"
        >
          <img
            src="@/assets/img/adsImg/ads1.jpg"
            width="280px"
            height="380px"
            alt="advertasing"
            style="object-fit: cover; border-radius: 20px; margin-right: 20px"
          />
          <img
            src="@/assets/img/adsImg/ads2.jpg"
            width="280px"
            height="380px"
            alt="advertasing"
            style="object-fit: cover; border-radius: 20px; margin-right: 20px"
          />
          <img
            src="@/assets/img/adsImg/ads3.jpg"
            width="280px"
            height="380px"
            alt="advertasing"
            style="object-fit: cover; border-radius: 20px; margin-right: 20px; cursor:pointer;"
            @click="pushToTrip()"
          />
          <img
            src="@/assets/img/adsImg/ads4.jpg"
            width="280px"
            height="380px"
            alt="advertasing"
            style="
              object-fit: cover;
              border-radius: 20px;
              box-shadow: -1px -3px 5px 0px rgba(0, 0, 0, 0.1);
              -webkit-box-shadow: -1px -3px 5px 0px rgba(0, 0, 0, 0.1);
              -moz-box-shadow: -1px -3px 5px 0px rgba(0, 0, 0, 0.1);
              cursor: pointer;
            "
            @click="showVideoModal = true"
          />
        </v-row>
        <swiper
          v-else
          :options="swiperMobileOption"
          ref="mySwiper"
          style="margin-top: 64px"
        >
          <swiper-slide style="text-align: center">
            <img
              src="@/assets/img/adsImg/ads1.jpg"
              width="280px"
              height="380px"
              alt="advertasing"
              style="object-fit: cover; border-radius: 20px; margin-right: 4px"
            />
          </swiper-slide>
          <swiper-slide style="text-align: center">
            <img
              src="@/assets/img/adsImg/ads2.jpg"
              width="280px"
              height="380px"
              alt="advertasing"
              style="object-fit: cover; border-radius: 20px; margin-right: 4px"
            />
          </swiper-slide>
          <swiper-slide style="text-align: center">
            <img
              src="@/assets/img/adsImg/ads3.jpg"
              width="280px"
              height="380px"
              alt="advertasing"
              style="object-fit: cover; border-radius: 20px; margin-right: 4px"
              @click="pushToTrip()"
            />
          </swiper-slide>
          <swiper-slide style="text-align: center">
            <img
              src="@/assets/img/adsImg/ads4.jpg"
              width="280px"
              height="380px"
              alt="advertasing"
              style="
                object-fit: cover;
                border-radius: 20px;
                margin-right: 4px;
                cursor: pointer;
              "
              @click="showVideoModal = true"
            />
          </swiper-slide>
        </swiper>
      </v-container>
    </v-main>
    <!-- <div
      class="mainBackground"
      :style="$vuetify.breakpoint.xs ? 'height: 380px' : ''"
    >
      <h1
        class="mainText"
        :style="
          $vuetify.breakpoint.xs
            ? 'padding-top: 20px; font-size: 18px;'
            : ' font-size: 40px;'
        "
      >
        Подорожуйте разом з нами!
      </h1>
      <v-row no-gutters justify="center">
        <h2 class="subtitle">КУПУЙТЕ КВИТКИ НА НАШОМУ САЙТІ - ЦЕ ЗРУЧНО !</h2>
      </v-row>
    </div> -->
    <div>
      <!--
         <div
          style="backdrop-filter: blur(5px); padding: 10px; width: max-content"
          :style="
            $vuetify.breakpoint.smAndDown
              ? 'margin-top: 2px;'
              : 'margin-top: 20px;'
          "
        >
        та отримайте
            <span
              style="
                font-weight: 600;
                background-color: rgb(255, 0, 0);
                color: #ffffff;
              "
            >
              знижку -10%</span
            >
            до Нового року <span></span>  </div>-->
      <v-row
        :justify="$vuetify.breakpoint.xs ? 'center' : 'start'"
        align="center"
      >
        <v-col cols="1" class="px-0" v-if="!$vuetify.breakpoint.xs">
          <!-- <v-row
          justify="start"
          no-gutters
          class="ml-5"
          v-for="item in socialNetworks"
          :key="item.id"
        >
          <a
            :href="item.value"
            target="_blank"
            :class="item.icon ? 'mb-2' : ''"
          >
            <v-icon v-if="item.social_network == 'Instagram'" color="black"
              >mdi-instagram</v-icon
            >
            <v-icon v-if="item.social_network == 'Facebook'" color="black"
              >mdi-facebook</v-icon
            >
            <v-icon v-if="item.social_network == 'Whatsapp'" color="black"
              >mdi-whatsapp</v-icon
            >
            <v-icon v-if="item.social_network == 'Twitter'" color="black"
              >mdi-twitter</v-icon
            >
            <img
              width="24px"
              height="20px"
              v-if="item.social_network == 'Viber'"
              src="../../../assets/img/viberIconBlack.svg"
              alt="viber"
              class="mb-1 mt-1"
            />
            <img
              width="20px"
              height="18px"
              v-if="item.social_network == 'Telegram'"
              src="../../../assets/img/telegramIconBlack.svg"
              alt="telegram"
              class="mb-1"
            />
          </a>
        </v-row> -->
        </v-col>
        <!-- <v-col
          cols="10"
          :style="
            $vuetify.breakpoint.xs ? 'margin-top: 20px;' : 'margin-top: 30px'
          "
        >
          <h3
            v-if="!$vuetify.breakpoint.xs"
            class="searchRoutes"
            :style="
              $vuetify.breakpoint.xs ? 'font-size: 16px;' : ' font-size: 20px;'
            "
          >
            Пошук автобусних рейсів:
          </h3>
          <search-routes-field-mobile v-if="$vuetify.breakpoint.xs" />
          <v-row v-else justify="center" no-gutters>
             :style="
              startRouteError.length || endRouteError.length
                ? 'height: 70px;'
                : 'height: 60px;'
            "  <div class="backgroundSearchField">
              <div v-if="loader" class="d-flex align-center mt-3">
                <v-progress-circular
                  class="mx-auto"
                  indeterminate
                  color="#085895"
                ></v-progress-circular>
              </div>
              <search-routes-field v-show="!loader" />
            </div> 
          </v-row>
        </v-col> -->
      </v-row>
    </div>
    <video-modal
      v-if="showVideoModal"
      :visible="showVideoModal"
      @close="showVideoModal = false"
    />
  </div>
</template>

<script>
//import searchRoutesFieldMobile from "./mainMobile/searchRoutesFieldMobile.vue";
import searchRoutesField from "@/components/UI/searchRoutesField.vue";
import Header from "../../Header.vue";
import { mapGetters, mapActions } from "vuex";
import swiperOptionsMixin from "@/mixins/swiperOptionsMixin";
import VideoModal from "../../UI/modals/videoModal.vue";
export default {
  components: {
    //searchRoutesFieldMobile,
    searchRoutesField,
    Header,
    VideoModal,
  },
  mixins: [swiperOptionsMixin],
  data: () => ({
    socialNetworks: [],
    start_route: "",
    end_route: "",
    nextCities: [],
    startCities: [],
    swiperMobileOption: {
      roundLengths: true,
      loop: false,
      slidesPerView: 1,
      centeredSlide: true,
    },
    showVideoModal: false,
  }),
  mounted() {
    setTimeout(() => {
      this.updateLoader(false);
    }, 3000);
  },
  methods: {
    ...mapActions(["updateLoader"]),
    pushToTrip() {
      this.$router.push(
        `/tickets?departureCity=98e22a30-d372-4ded-b994-b3a2cc18f79d&arrivalCity=98e227f6-5d44-4ff4-a3fa-ce21e6a97399&date=${new Date()
          .toISOString()
          .substring(0, 10)}&quantity_adult=1&quantity_child=0`
      );
    },
  },
  computed: {
    ...mapGetters(["loader"]),
  },
};
</script>

<style scoped>
@media only screen and (min-width: 1024px) {
  .mainBackground {
    height: 450px;
    background: linear-gradient(
        0deg,
        rgba(36, 57, 73, 0.5),
        rgba(36, 57, 73, 0.5)
      ),
      url("../../../assets/img/mainBackground.png");
    text-align: center;
    background-repeat: no-repeat;
    background-size: cover;
  }
  .searchRouteBackground {
    border-radius: 10px;
    background: #fff;
    box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.1);
    width: 100%;
    padding: 40px;
    z-index: 20;
    position: relative;
  }
}
@media only screen and (max-width: 1023px) {
  .mainBackground {
    height: 700px;
    background: linear-gradient(
        0deg,
        rgba(36, 57, 73, 0.5),
        rgba(36, 57, 73, 0.5)
      ),
      url("../../../assets/img/mainBackgroundMobile.jpg");
    text-align: center;
    object-fit: cover;
    width: 100%;
    background-repeat: no-repeat;
    background-size: cover;
  }
}

.mainText {
  font-size: 40px;
  line-height: 40px;
  letter-spacing: 0.1em;
  text-transform: uppercase;
  color: #1b1b1b;
  font-family: Roboto;
  font-weight: 600;
}

.searchRoutes {
  font-style: normal;
  font-weight: 400;
  letter-spacing: 0.1em;
  color: #ffffff;
  margin-bottom: 20px;
}

.backgroundSearchField {
  width: 900px;
  height: 80px;
  padding: 12px;
  background: rgba(255, 255, 255, 0.9);
  border: 0.5px solid #085895;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.15);
  border-radius: 30px;
  text-align: center;
  align-self: center;
}
@media only screen and (min-width: 600px) {
  .subtitle {
    font-weight: 400;
    font-size: 20px;
    line-height: 23px;
    letter-spacing: 0.1em;
    color: #1b1b1b;
    margin-top: 20px;
    margin-bottom: 0px;
  }
}
@media only screen and (max-width: 599px) {
  .subtitle {
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    text-align: center;
    letter-spacing: 0.1em;
    color: #1b1b1b;
    margin-top: 12px;
    margin-bottom: 0px;
    text-align: left;
  }
  .mainText {
    font-size: 20px;
    letter-spacing: 0.1em;
    color: #1b1b1b;
    font-family: Roboto;
    font-weight: 600;
  }
  .searchRouteBackground {
    padding: 30px;
    border-radius: 10px;
    background: #fff;
    box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.1);
    position: static;
  }
}
</style>
<style>
.header .v-sheet.v-toolbar:not(.v-sheet--outlined) {
  box-shadow: none;
}
.header .v-sheet.v-toolbar:not(.v-sheet--outlined) {
  box-shadow: none;
}
.header .v-sheet.v-app-bar.v-toolbar:not(.v-sheet--outlined) {
  box-shadow: none;
}
</style>