<template>
  <v-dialog v-model="visibility" width="100%" max-width="600px">
    <v-card>
      <Loader v-if="loading" />
      <iframe
        v-show="!loading"
        :height="$vuetify.breakpoint.smAndDown ? '375px' : '600px'"
        width="100%"
        :src="`https://www.youtube.com/embed/${videoId}`"
        frameborder="0"
        allowfullscreen
        loading="lazy"
        title="Youtube video"
      ></iframe>
    </v-card>
  </v-dialog>
</template>

<script>
import modalMixin from "@/mixins/modalMixin";
import Loader from "../Loader.vue";
export default {
  components: {
    Loader,
  },
  mixins: [modalMixin],
  data: () => ({
    loading: true,
    videoId: "",
  }),
  mounted() {
    this.setVideoId();
  },
  methods: {
    setVideoId() {
      this.videoId = this.$youtube.getIdFromUrl("https://youtu.be/l0NcGyEo6oA");
      this.loading = false;
    },
  },
};
</script>

<style>
</style>