<template>
  <div class="backgroundImg">
    <v-main>
      <v-container>
        <div class="contactBackground">
          <v-row no-gutters v-if="!$vuetify.breakpoint.xs">
            <v-col cols="7" style="text-align: left">
              <contact-info />
            </v-col>
            <v-col cols="5">
              <v-row no-gutters justify="center">
                <contact-user-form />
              </v-row>
            </v-col>
          </v-row>
          <v-col v-else class="px-0">
            <v-col class="px-0 py-0">
              <contact-info />
            </v-col>
            <v-col class="px-0">
              <contact-user-form />
            </v-col>
          </v-col>
        </div>
      </v-container>
    </v-main>
  </div>
</template>

<script>
import contactUserForm from "../Contact/contactUserForm.vue";
import contactInfo from "../Contact/contactInfo.vue";
export default {
  components: {
    contactUserForm,
    contactInfo,
  },
};
</script>

<style scoped>
@media only screen and (max-width: 1023px) {
  .contactBackground {
    padding: 10px 0px;
  }
}
@media only screen and (min-width: 1024px) {
  .contactBackground {
    padding: 50px 0px;
  }
}
.backgroundImg {
  background-image: url("../../../assets/img/mapBackground.png");
}
</style>