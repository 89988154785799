var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"reviewBackground"},[(_vm.showSuccessSnackbar)?_c('success-snackbar',{attrs:{"snackbarText":_vm.snackbarText}}):_vm._e(),(!_vm.$vuetify.breakpoint.smAndDown)?_c('v-main',[_c('v-container',[_c('v-row',{attrs:{"no-gutters":"","justify":"center"}},[_c('p',{staticClass:"reviewSectionTitle"},[_vm._v("відгуки наших пасажирів")])]),_c('div',{staticStyle:{"position":"absolute","margin-top":"100px"},style:(_vm.$vuetify.breakpoint.xl ? 'width: 91svw' : 'width: 80svw;')},[_c('v-row',{attrs:{"no-gutters":""}},[_c('div',{staticClass:"swiperBtnBackground swiper-review-button-prev",style:(_vm.activeSlide == 1 ? 'pointer-events:none;' : ''),attrs:{"id":"prev-btn"},on:{"click":function($event){_vm.activeSlide--}}},[_c('img',{attrs:{"src":require("../../../../assets/img/iconsSvg/nextReviewIcon.svg"),"alt":"next slide"}})]),_c('v-spacer'),_c('div',{staticClass:"swiperBtnBackground swiper-review-button-next",style:(_vm.activeSlide >= _vm.reviews.length ? 'pointer-events:none;' : ''),on:{"click":function($event){_vm.activeSlide++}}},[_c('img',{staticStyle:{"transform":"rotate(0.5turn)"},attrs:{"src":require("../../../../assets/img/iconsSvg/nextReviewIcon.svg"),"alt":"next slide"}})])],1)],1),_c('v-col',{directives:[{name:"show",rawName:"v-show",value:(_vm.swiperInitialized),expression:"swiperInitialized"}],key:_vm.key,attrs:{"cols":"7","md":"12","lg":"12","xl":"12"}},[(_vm.$vuetify.breakpoint.xl)?_c('swiper',{ref:"mySwiper",style:({
            '--swiper-navigation-color': '#000000',
          }),attrs:{"options":_vm.swiperLargeOption}},_vm._l((_vm.reviews),function(review){return _c('swiper-slide',{key:review.id,staticClass:"mainSwiper",staticStyle:{"width":"10px"}},[_c('review-card',{attrs:{"review":review}})],1)}),1):_vm._e(),(_vm.$vuetify.breakpoint.lg)?_c('swiper',{ref:"mySwiper",style:({
            '--swiper-navigation-color': '#000000',
          }),attrs:{"options":_vm.swiperOption,"updateOnWindowResize":true}},_vm._l((_vm.reviews),function(review){return _c('swiper-slide',{key:review.id,staticClass:"mainSwiper",staticStyle:{"width":"250px"}},[_c('review-card',{staticStyle:{"width":"250px"},attrs:{"review":review}})],1)}),1):_vm._e(),(_vm.$vuetify.breakpoint.md)?_c('swiper',{style:({
            '--swiper-navigation-color': '#000000',
          }),attrs:{"options":_vm.swiperMediumOption}},_vm._l((_vm.reviews),function(review){return _c('swiper-slide',{key:review.id,ref:"mySwiper",refInFor:true,staticClass:"mainSwiper",staticStyle:{"width":"50px"}},[_c('review-card',{staticStyle:{"width":"250px"},attrs:{"review":review}})],1)}),1):_vm._e()],1),_c('v-row',{staticStyle:{"margin-top":"64px"},attrs:{"no-gutters":"","justify":"center"}},[_c('p',{staticClass:"createReviewBtn",on:{"click":function($event){_vm.showCreateReviewModal = true}}},[_vm._v(" Залишити відгук ")])])],1)],1):_c('v-container',[_c('v-row',{attrs:{"no-gutters":"","justify":"center"}},[_c('p',{staticClass:"reviewSectionTitle",staticStyle:{"margin-bottom":"40px"}},[_vm._v(" відгуки наших пасажирів ")])]),_c('div',{staticStyle:{"width":"100svw","position":"absolute","margin-top":"100px","left":"0"}},[_c('v-row',{attrs:{"no-gutters":"","align":"center"}},[_c('div',{staticClass:"swiperBtnBackground swiper-review-button-prev",style:(_vm.activeSlide == 1 ? 'pointer-events:none;' : ''),attrs:{"id":"prev-btn"},on:{"click":function($event){_vm.activeSlide--}}},[_c('img',{attrs:{"src":require("../../../../assets/img/iconsSvg/nextReviewIcon.svg"),"alt":"prev slide","width":"16px","height":"42px"}})]),_c('v-spacer'),_c('div',{staticClass:"swiperBtnBackground swiper-review-button-next",style:(_vm.activeSlide >= _vm.reviews.length ? 'pointer-events:none;' : ''),on:{"click":function($event){_vm.activeSlide++}}},[_c('img',{staticStyle:{"transform":"rotate(0.5turn)"},attrs:{"src":require("../../../../assets/img/iconsSvg/nextReviewIcon.svg"),"alt":"next slide","width":"16px","height":"42px"}})])],1)],1),_c('v-col',{directives:[{name:"show",rawName:"v-show",value:(_vm.swiperInitialized),expression:"swiperInitialized"}],key:_vm.key,attrs:{"cols":"12","md":"12","lg":"12","xl":"12"}},[(_vm.$vuetify.breakpoint.smAndDown)?_c('swiper',{ref:"mySwiper",staticClass:"swiper d-block d-md-none",style:({
          '--swiper-navigation-color': '#000000',
        }),attrs:{"options":_vm.swiperMobileOption}},_vm._l((_vm.reviews),function(review){return _c('swiper-slide',{key:review.id,staticClass:"mainSwiper"},[_c('v-row',{attrs:{"no-gutters":"","justify":"center"}},[_c('review-card',{staticStyle:{"width":"80% !important"},attrs:{"review":review}})],1)],1)}),1):_vm._e()],1),_c('v-row',{staticStyle:{"margin-top":"64px"},attrs:{"no-gutters":"","justify":"center"}},[_c('p',{staticClass:"createReviewBtn",on:{"click":function($event){_vm.showCreateReviewModal = true}}},[_vm._v(" Залишити відгук ")])])],1),(_vm.showCreateReviewModal)?_c('create-review-modal',{attrs:{"visible":_vm.showCreateReviewModal},on:{"createReview":_vm.createReview,"close":function($event){_vm.showCreateReviewModal = false}}}):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }