<template>
  <v-main>
    <v-container>
      <div>
        <v-col
          :style="
            $vuetify.breakpoint.xs ? 'margin-top: 30px' : 'margin-top: 100px'
          "
          class="px-0"
        >
          <p
            class="howItWorkText"
            :style="
              $vuetify.breakpoint.xs
                ? 'margin-bottom: 24px;'
                : 'margin-bottom: 50px;'
            "
          >
            Як купити квиток?
          </p>
          <v-row
            no-gutters
            align="start"
            justify="center"
            :style="$vuetify.breakpoint.xs ? '' : ''"
            class="px-0"
          >
            <v-col
              cols="12"
              xl="3"
              lg="3"
              md="3"
              sm="3"
              v-for="(item, index) in howItWork"
              :key="item.id"
              class="px-0 py-0"
              :style="
                $vuetify.breakpoint.smAndDown
                  ? 'margin-top: 40px'
                  : 'margin-top: 64px'
              "
              :ref="item.icon"
            >
              <div
                style="display: flex; width: 100%; align-items: center"
                :style="
                  $vuetify.breakpoint.xs
                    ? 'text-align: center; justify-content: center;'
                    : ''
                "
              >
                <v-row no-gutters justify="center">
                  <v-col cols="12" xl="10" lg="10" md="10" sm="10" class="py-0">
                    <v-row no-gutters justify="center">
                      <div
                        style="width: 80px"
                        :class="{ stepIconAnimation: index == toElementHover }"
                        class="stepIcon"
                      >
                        <div :class="item.icon"></div>
                      </div>
                    </v-row>
                  </v-col>
                </v-row>
              </div>
              <v-row no-gutters justify="center">
                <v-col
                  cols="12"
                  xl="10"
                  lg="10"
                  md="10"
                  sm="10"
                  :style="
                    $vuetify.breakpoint.xs
                      ? 'text-align: center;'
                      : 'text-align: center'
                  "
                  class="px-0"
                  style="margin-top: 40px"
                >
                  <span class="stepTitle">{{ item.title }}</span>
                  <p class="stepText" style="margin-top: 12px">
                    {{ item.text }}
                  </p>
                    <v-row
                      no-gutters
                      justify="center"
                      v-if="
                        toElementHover == index && $vuetify.breakpoint.smAndDown
                      "
                      class="test"
                    >
                      <div class="stepIndex">
                        {{ index + 1 }}
                      </div>
                    </v-row>
                </v-col>
              </v-row>
              <v-row no-gutters justify="center">
                <div
                  v-if="$vuetify.breakpoint.xs && index < 3"
                  style="
                    border: 1px solid #b5b5b5;
                    width: 0px;
                    height: 100px;
                    margin-bottom: 5px;
                    margin-top: 40px;
                  "
                  :style="
                    toElementHover == index ? 'border: 2px solid #085895; ' : ''
                  "
                ></div>
              </v-row>
            </v-col>
          </v-row>
          <v-row no-gutters :key="key" v-if="!$vuetify.breakpoint.smAndDown">
            <v-col
              cols="3"
              v-for="i in 4"
              :key="i"
              :style="i == toElementHover + 1 ? '' : 'margin-top: 120px'"
            >
              <v-row no-gutters justify="center" v-if="toElementHover + 1 == i">
                <div class="stepIndex">
                  {{ i }}
                </div>
              </v-row>
              <div
                style="border-bottom: 2px solid #b5b5b580"
                :style="
                  toElementHover == i - 1
                    ? 'border-color: #085895'
                    : '#b5b5b580'
                "
              />
            </v-col>
          </v-row>
        </v-col>
      </div>
    </v-container>
  </v-main>
</template>

<script>
export default {
  data: () => ({
    howItWork: [
      {
        id: 1,
        title: "Вказуєте куди вам потрібно їхати ",
        text: "Вкажіть пункт відправленняприбуття та дату поїздки",
        icon: "mapPointIcon",
      },
      {
        id: 2,
        text: "Порівняйте ціни, виберіть зручний час і місце ",
        title: "Обираєте квиток, який вам підходить",
        icon: "ticketIcon",
      },
      {
        id: 3,
        text: "Внесіть дані пасажира, обов’язково перевірте інформацію та оплатіть замовлення",
        title: "Оплачуєте квиток",
        icon: "payIcon",
      },
      {
        id: 4,
        text: "Роздрукуйте або перевірте електронний квиток. Насолоджуйтеся поїздкою.",
        title: "Насолоджуєтеся поїздкою",
        icon: "busIcon",
      },
    ],
    toElementHover: -1,
    key: 0,
    startAnimation: false,
    animationTimeout: 0,
  }),
  mounted() {
    //window.addEventListener("scroll", this.handleScroll); // Add a scroll event handler
    //this.startAnimationIcon();
  },
  methods: {
    onScroll() {
      if (this.$vuetify.breakpoint.smAndDown) {
        let mapPoint = this.$refs.mapPointIcon[0].getBoundingClientRect().top;
        let ticket = this.$refs.ticketIcon[0].getBoundingClientRect().top;
        let pay = this.$refs.payIcon[0].getBoundingClientRect().top;
        let bus = this.$refs.busIcon[0].getBoundingClientRect().top;
        if (mapPoint - 350 < 0) {
          this.toElementHover = 0;
        }
        if (ticket - 350 < 0) {
          this.toElementHover = 1;
        }
        if (pay - 350 < 0) {
          this.toElementHover = 2;
        }
        if (bus - 350 < 0) {
          this.toElementHover = 3;
        }
      }
    },
    // handleScroll() {
    //   const elementPosition = this.$el.getBoundingClientRect().top; // Get the position of the element on the screen
    //   const screenHeight = window.innerHeight; // Get the height of the screen
    //   if (elementPosition < screenHeight * 0.75) {
    //     if (this.$vuetify.breakpoint.mdAndUp) {
    //       this.startAnimationIcon(); //Start Animatiom
    //     }
    //   }
    //   window.removeEventListener("scroll", this.handleScroll);
    // },
    startAnimationIcon() {
      this.startAnimation = true;
      if (this.toElementHover < 3) {
        this.toElementHover++;
      } else {
        this.toElementHover = 0;
      }
      this.animationTimeout = setTimeout(() => {
        this.startAnimationIcon();
      }, 5000);
    },
  },
  watch: {
    toElementHover: {
      handler() {
        this.key++;
      },
    },
  },
};
</script>

<style scoped>
.howItWorkText {
  color: #1b1b1b;
  text-align: center;
  font-family: Roboto;
  font-size: 32px;
  font-style: normal;
  font-weight: 500;
}
.stepIcon {
  transition: all 2s ease-out;
}
.stepIconAnimation {
  transition: all 1s ease-in-out;
  transform: scale(1.5);
  transform-origin: center;
  margin-bottom: 30px;
}
.stepIconAnimation .mapPointIcon {
  background-color: rgb(8, 88, 149, 1);
}
.stepIconAnimation .busIcon {
  background-color: #085895;
}
.stepIconAnimation .ticketIcon {
  background-color: #085895;
}
.stepIconAnimation .payIcon {
  background-color: #085895;
}
.stepText {
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  text-align: center;
  color: #1b1b1b;
}
.stepTitle {
  font-weight: 600;
  font-size: 20px;
  line-height: 28px;
  text-align: center;
  color: #243949;
  z-index: 10;
}
@media only screen and (max-width: 600px) {
  .stepIndex {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #085895;
    font-weight: 600;
    font-size: 20px;
    line-height: 28px;
    color: #eeeeee;
    margin-top: 48px;
    margin-bottom: 32px;
    animation: slide-in 1s ease-in forwards;
  }
}
@media only screen and (min-width: 601px) {
  .stepIndex {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #085895;
    font-weight: 600;
    font-size: 20px;
    line-height: 28px;
    color: #eeeeee;
    margin-top: 48px;
    margin-bottom: 32px;
    animation: slide-in 1s ease-in forwards, slide-out 1s 4.5s ease-out forwards;
  }
}
@keyframes slide-in {
  0% {
    opacity: 0; /* Початкова прозорість елемента */
    transform: translateY(
      -100%
    ); /* Початкове положення елемента поза видимою областю (верхня частина) */
  }
  100% {
    opacity: 1; /* Кінцева прозорість елемента */
    transform: translateY(0); /* Кінцеве положення елемента (внизу) */
  }
}
@keyframes slide-out {
  0% {
    opacity: 1; /* Початкова прозорість елемента */
    transform: translateY(
      0
    ); /* Початкове положення елемента поза видимою областю (верхня частина) */
  }
  100% {
    opacity: 0; /* Кінцева прозорість елемента */
    transform: translateY(-100%); /* Кінцеве положення елемента (внизу) */
  }
}
.mapPointIcon {
  width: 80px;
  height: 80px;
  background-color: rgba(181, 181, 181, 0.5);
  -webkit-mask: url("../../../assets/img/howItWork/mapPointIcon.svg") no-repeat center;
  mask: url("../../../assets/img/howItWork/mapPointIcon.svg") no-repeat center;
}
.ticketIcon {
  width: 80px;
  height: 80px;
  background-color: rgba(181, 181, 181, 0.5);
  -webkit-mask: url("../../../assets/img/howItWork/ticketIcon.svg") no-repeat center;
  mask: url("../../../assets/img/howItWork/ticketIcon.svg") no-repeat center;
}
.payIcon {
  width: 80px;
  height: 80px;
  background-color: rgba(181, 181, 181, 0.5);
  -webkit-mask: url("../../../assets/img/howItWork/payIcon.svg") no-repeat center;
  mask: url("../../../assets/img/howItWork/payIcon.svg") no-repeat center;
}
.busIcon {
  width: 80px;
  height: 80px;
  background-color: rgba(181, 181, 181, 0.5);
  -webkit-mask: url("../../../assets/img/howItWork/busIcon.svg") no-repeat center;
  mask: url("../../../assets/img/howItWork/busIcon.svg") no-repeat center;
}
</style>