var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-main',[_c('v-container',[_c('div',[_c('v-col',{staticClass:"px-0",style:(_vm.$vuetify.breakpoint.xs ? 'margin-top: 30px' : 'margin-top: 100px')},[_c('p',{staticClass:"howItWorkText",style:(_vm.$vuetify.breakpoint.xs
              ? 'margin-bottom: 24px;'
              : 'margin-bottom: 50px;')},[_vm._v(" Як купити квиток? ")]),_c('v-row',{staticClass:"px-0",style:(_vm.$vuetify.breakpoint.xs ? '' : ''),attrs:{"no-gutters":"","align":"start","justify":"center"}},_vm._l((_vm.howItWork),function(item,index){return _c('v-col',{key:item.id,ref:item.icon,refInFor:true,staticClass:"px-0 py-0",style:(_vm.$vuetify.breakpoint.smAndDown
                ? 'margin-top: 40px'
                : 'margin-top: 64px'),attrs:{"cols":"12","xl":"3","lg":"3","md":"3","sm":"3"}},[_c('div',{staticStyle:{"display":"flex","width":"100%","align-items":"center"},style:(_vm.$vuetify.breakpoint.xs
                  ? 'text-align: center; justify-content: center;'
                  : '')},[_c('v-row',{attrs:{"no-gutters":"","justify":"center"}},[_c('v-col',{staticClass:"py-0",attrs:{"cols":"12","xl":"10","lg":"10","md":"10","sm":"10"}},[_c('v-row',{attrs:{"no-gutters":"","justify":"center"}},[_c('div',{staticClass:"stepIcon",class:{ stepIconAnimation: index == _vm.toElementHover },staticStyle:{"width":"80px"}},[_c('div',{class:item.icon})])])],1)],1)],1),_c('v-row',{attrs:{"no-gutters":"","justify":"center"}},[_c('v-col',{staticClass:"px-0",staticStyle:{"margin-top":"40px"},style:(_vm.$vuetify.breakpoint.xs
                    ? 'text-align: center;'
                    : 'text-align: center'),attrs:{"cols":"12","xl":"10","lg":"10","md":"10","sm":"10"}},[_c('span',{staticClass:"stepTitle"},[_vm._v(_vm._s(item.title))]),_c('p',{staticClass:"stepText",staticStyle:{"margin-top":"12px"}},[_vm._v(" "+_vm._s(item.text)+" ")]),(
                      _vm.toElementHover == index && _vm.$vuetify.breakpoint.smAndDown
                    )?_c('v-row',{staticClass:"test",attrs:{"no-gutters":"","justify":"center"}},[_c('div',{staticClass:"stepIndex"},[_vm._v(" "+_vm._s(index + 1)+" ")])]):_vm._e()],1)],1),_c('v-row',{attrs:{"no-gutters":"","justify":"center"}},[(_vm.$vuetify.breakpoint.xs && index < 3)?_c('div',{staticStyle:{"border":"1px solid #b5b5b5","width":"0px","height":"100px","margin-bottom":"5px","margin-top":"40px"},style:(_vm.toElementHover == index ? 'border: 2px solid #085895; ' : '')}):_vm._e()])],1)}),1),(!_vm.$vuetify.breakpoint.smAndDown)?_c('v-row',{key:_vm.key,attrs:{"no-gutters":""}},_vm._l((4),function(i){return _c('v-col',{key:i,style:(i == _vm.toElementHover + 1 ? '' : 'margin-top: 120px'),attrs:{"cols":"3"}},[(_vm.toElementHover + 1 == i)?_c('v-row',{attrs:{"no-gutters":"","justify":"center"}},[_c('div',{staticClass:"stepIndex"},[_vm._v(" "+_vm._s(i)+" ")])]):_vm._e(),_c('div',{staticStyle:{"border-bottom":"2px solid #b5b5b580"},style:(_vm.toElementHover == i - 1
                  ? 'border-color: #085895'
                  : '#b5b5b580')})],1)}),1):_vm._e()],1)],1)])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }