<template>
  <div style="overflow-x: hidden">
    <meta name="robots" content="index, follow" />
    <main-search-routes />
    <about-us />
    <!-- <about-us-mobile /> -->
    <!-- <our-routes /> -->
    <popular-routes-main style="margin: 50px 0px" />
    <main-irregular-transportation :content="irregularTransportationContent" />
    <reviews-component />
    <how-it-work
      :style="$vuetify.breakpoint.smAndDown ? '' : 'height: 700px'"
    />

    <about-us-description
      style="margin-top: 120px"
      :content="aboutUsContent"
      :youtubeVideoLink="youtubeVideoLink"
      @goToContactForm="$vuetify.goTo('#contactForm')"
    />
    <main-contact id="contactForm" />
  </div>
</template>

<script>
import mainSearchRoutes from "./mainSearchRoutes.vue";
import aboutUs from "./aboutUs.vue";
//import aboutUsMobile from "./mainMobile/aboutUsMobile.vue";
import mainIrregularTransportation from "./mainIrregularTransportation.vue";
//import irregularTransportationMobile from "./mainMobile/irregularTransportationMobile.vue";
//import ourRoutes from "@/components/forUser/Main/ourRoutes";
import aboutUsDescription from "./aboutUsDescription.vue";
import mainContact from "./mainContact.vue";
import contentService from "@/requests/admin/contentService";
import HowItWork from "./howItWork.vue";
import ReviewsComponent from "./reviews/reviewsComponent.vue";
import popularRoutesMain from "./popularRoutesMain.vue";
export default {
  name: "mainComponent",
  components: {
    mainSearchRoutes,
    aboutUs,
    // aboutUsMobile,
    mainIrregularTransportation,
    //irregularTransportationMobile,
    //ourRoutes,
    aboutUsDescription,
    mainContact,
    HowItWork,
    ReviewsComponent,
    popularRoutesMain,
  },
  data: () => ({
    aboutUsContent: [],
    irregularTransportationContent: [],
    youtubeVideoLink: [],
  }),
  mounted() {
    document.title = `Квитки на Автобус онлайн від Mbus: Розклад Руху, Ціна, Купити`;
    this.getContent();
  },
  methods: {
    async getContent() {
      let response = await contentService.getContents();
      response.data.forEach((content) => {
        content.section == "irregular_transportation"
          ? this.irregularTransportationContent.push(content)
          : content.section == "about_us"
          ? this.aboutUsContent.push(content)
          : content.section == "youtube_video_link"
          ? this.youtubeVideoLink.push(content)
          : "";
      });
    },
  },
};
</script>

<style scoped>
</style>