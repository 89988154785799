<template>
  <div
    style="background-color: #122b3e"
    :style="
      $vuetify.breakpoint.smAndDown ? 'padding: 40px 0px' : 'padding: 40px'
    "
  >
    <v-main v-if="!$vuetify.breakpoint.smAndDown">
      <v-container>
        <div loading="lazy">
          <iframe
            :height="$vuetify.breakpoint.smAndDown ? '375px' : '600px'"
            :width="$vuetify.breakpoint.smAndDown ? '100%' : '1180px'"
            :src="`https://www.youtube.com/embed/${videoId}`"
            frameborder="0"
            allowfullscreen
            loading="lazy"
            title="Youtube video"
          ></iframe>
        </div>
        <v-row
          no-gutters
          align="center"
          justify="center"
          style="margin-top: 48px"
        >
          <v-col cols="6" style="text-align: left">
            <p class="aboutUsTitle">
              Подорожуйте разом з <span style="color: #085895">Mbus</span>
            </p>
            <div
              v-html="content[0]?.content"
              class="aboutUsText"
              style="margin-bottom: 38px"
            ></div>
            <p class="aboutUsSubtitle">Ми запрошуємо вас до співпраці</p>
            <v-btn class="contactUsBtn" @click="$emit('goToContactForm')"
              >Зв’язатися з нами</v-btn
            >
          </v-col>
          <v-col cols="6" style="text-align: center">
            <!-- <v-row no-gutters justify="center">
              <v-icon :size="56">mdi-chevron-up-circle-outline</v-icon>
            </v-row> -->
            <div
              style="height: 820px; overflow: hidden; text-align: center"
              :style="$vuetify.breakpoint.xl ? 'height: 900px;' : ''"
              v-if="showImageBlock"
            >
              <div
                class="swiper-button-prev-about-us"
                slot="button-prev"
                style="margin-bottom: 20px"
              >
                <img
                  src="@/assets/img/iconsSvg/swiperBtn.svg"
                  alt="swiper btn"
                  width="56px"
                  height="56px"
                  style="transform: rotate(90deg)"
                />
              </div>
              <div
                class="swiper-button-next-about-us"
                slot="button-next"
                style="
                  position: absolute;
                  margin-top: 760px;
                  margin-left: 250px;
                  z-index: 100;
                "
                :style="$vuetify.breakpoint.xl ? 'margin-left: 410px;' : ''"
              >
                <img
                  src="@/assets/img/iconsSvg/swiperBtn.svg"
                  alt="swiper btn"
                  width="56px"
                  height="56px"
                  style="transform: rotate(270deg)"
                />
              </div>
              <v-row no-gutters justify="center">
                <swiper
                  v-if="$vuetify.breakpoint.xl"
                  :style="{
                    '--swiper-navigation-color': '#000000',
                  }"
                  :options="swiperLargeOption"
                  ref="mySwiper"
                  style="height: 800px"
                >
                  <swiper-slide
                    v-for="image in content[0].images"
                    :key="image.id"
                    style="width: 380px"
                  >
                    <img
                      :src="image?.images?.path"
                      width="380px"
                      height="200px"
                      alt="bus img"
                      style="object-fit: cover"
                      loading="lazy"
                    />
                  </swiper-slide>
                </swiper>
                <swiper
                  v-if="$vuetify.breakpoint.lg"
                  :style="{
                    '--swiper-navigation-color': '#000000',
                  }"
                  :options="swiperOption"
                  ref="mySwiper"
                  style="height: 800px"
                  :key="key"
                >
                  <swiper-slide
                    v-for="image in content[0].images"
                    :key="image.id"
                    style="width: 380px"
                  >
                    <img
                      :src="image?.images?.path"
                      width="380px"
                      height="200px"
                      alt="bus img"
                      style="object-fit: cover"
                      loading="lazy"
                    />
                  </swiper-slide>
                </swiper>
                <swiper
                  v-if="$vuetify.breakpoint.md"
                  :style="{
                    '--swiper-navigation-color': '#000000',
                  }"
                  :options="swiperMediumOption"
                >
                  <swiper-slide
                    v-for="image in content[0].images"
                    :key="image.id"
                    style="width: 50px"
                  >
                    <!-- <img
                      :src="image?.images?.path"
                      width="380px"
                      height="200px"
                      alt="bus img"
                      style="object-fit: cover;"
                    /> /> -->
                  </swiper-slide>
                </swiper>
                <swiper
                  v-if="$vuetify.breakpoint.smAndDown"
                  class="swiper d-block d-md-none"
                  :style="{
                    '--swiper-navigation-color': '#000000',
                  }"
                  :options="swiperMobileOption"
                >
                  <swiper-slide
                    v-for="image in content[0].images"
                    :key="image.id"
                  >
                    <v-row no-gutters justify="center">
                      <!-- <img
                      :src="image?.images?.path"
                      width="380px"
                      height="200px"
                      alt="bus img"
                      style="object-fit: cover;"
                    />
                  /> -->
                    </v-row>
                  </swiper-slide>
                  <!-- <swiper-slide v-for="route in routes" :key="route.id">
              <routes-card :route="route" />
            </swiper-slide> -->
                </swiper>
              </v-row>
            </div>
          </v-col>
        </v-row>
      </v-container>
    </v-main>
    <v-container v-else>
      <div loading="lazy">
        <iframe
          :height="$vuetify.breakpoint.smAndDown ? '375px' : '600px'"
          :width="$vuetify.breakpoint.smAndDown ? '100%' : '1180px'"
          :src="`https://www.youtube.com/embed/${videoId}`"
          frameborder="0"
          allowfullscreen
          loading="lazy"
          title="Youtube video"
        ></iframe>
      </div>
      <v-row
        no-gutters
        align="center"
        justify="center"
        style="margin-top: 48px"
      >
        <v-col cols="12" style="text-align: left">
          <p class="aboutUsTitle">
            Подорожуйте разом з <span style="color: #085895">Mbus</span>
          </p>
          <div
            v-html="content[0]?.content"
            class="aboutUsText"
            style="margin-bottom: 38px"
          ></div>
          <p class="aboutUsSubtitle">Ми запрошуємо вас до співпраці</p>
          <v-row no-gutters justify="center">
            <v-btn
              class="contactUsBtn"
              width="100%"
              @click="$emit('goToContactForm')"
              >Зв’язатися з нами</v-btn
            >
          </v-row>
        </v-col>
        <v-col cols="12" style="text-align: center">
          <div
            style="
              height: 256px;
              overflow: hidden;
              text-align: center;
              margin-top: 30px;
            "
            v-if="showImageBlock"
          >
            <v-row
              no-gutters
              align="center"
              justify="end"
              style="margin-bottom: 20px"
            >
              <div
                class="swiper-button-prev-about-us"
                slot="button-prev"
                style="margin-right: 20px"
              >
                <img
                  src="@/assets/img/iconsSvg/swiperBtn.svg"
                  alt="swiper btn"
                  width="56px"
                  height="56px"
                />
              </div>
              <div class="swiper-button-next-about-us" slot="button-next">
                <img
                  src="@/assets/img/iconsSvg/swiperBtn.svg"
                  alt="swiper btn"
                  width="56px"
                  height="56px"
                  style="transform: rotate(180deg)"
                />
              </div>
            </v-row>

            <v-row no-gutters justify="center">
              <swiper
                v-if="$vuetify.breakpoint.smAndDown"
                class="swiper d-block d-md-none"
                :style="{
                  '--swiper-navigation-color': '#000000',
                }"
                :options="swiperMobileOption"
              >
                <swiper-slide
                  v-for="image in content[0].images"
                  :key="image.id"
                >
                  <img
                    :src="image?.images?.path"
                    width="350px"
                    height="180px"
                    alt="bus img"
                    style="object-fit: cover"
                    loading="lazy"
                  />
                  />
                </swiper-slide>
              </swiper>
            </v-row>
          </div>
        </v-col>
      </v-row>
    </v-container>
  </div>
  <!-- <v-main
    ><v-container>
      
      <v-row justify="center">
        <v-col style="text-align: left">
          <p
            :class="$vuetify.breakpoint.xs ? 'mainTitleMobile' : 'mainTitle'"
            style="text-align: center"
          >
            Про нас
          </p>
          <v-row align="start" class="mt-5">
            <v-col
              cols="12"
              xl="6"
              lg="6"
              md="6"
              sm="6"
              style="z-index: 10"
              class="py-0"
            >
              <span
                :class="
                  !$vuetify.breakpoint.xs
                    ? 'otherTextDescription'
                    : isMore
                    ? 'mobileTextDescription'
                    : 'mobileTextDescriptionOverflow'
                "
              >
                <div v-if="content.length" v-html="content[0].content"></div>
              </span>
              <v-row
                no-gutters
                align="start"
                justify="end"
                v-if="$vuetify.breakpoint.xs"
                class="mt-2"
              >
                <v-btn
                  outlined
                  @click="isMore = !isMore"
                  color="#085895"
                  width="85px"
                  height="20px"
                  style="text-transform: none; font-size: 10px"
                >
                  <span v-if="!isMore">Більше</span>
                  <span v-else>Менше</span>
                </v-btn>
              </v-row>
            </v-col>
            <div
              v-if="content.length"
              :class="$vuetify.breakpoint.xs ? 'mobileLogo' : ''"
            >
              <img
                v-if="content[0].images.length"
                :height="
                  $vuetify.breakpoint.xs
                    ? '200px'
                    : $vuetify.breakpoint.sm
                    ? '200px'
                    : '346px'
                "
                :width="
                  $vuetify.breakpoint.xs
                    ? '280px'
                    : $vuetify.breakpoint.sm
                    ? '300px'
                    : '454px'
                "
                class="ml-10 mt-1"
                :src="
                  content[0].images[0].images
                    ? content[0].images[0].images.path
                    : ''
                "
                :style="
                  $vuetify.breakpoint.xs
                    ? 'opacity: 0.3; object-fit:cover;'
                    : 'opacity: 1; object-fit:cover;'
                "
                alt="фон"
              />
            </div>
          </v-row>
        </v-col>
      </v-row> </v-container
  ></v-main> -->
</template> 

<script>
import swiperOptionsMixin from "@/mixins/swiperOptionsMixin";
export default {
  mixins: [swiperOptionsMixin],
  data: () => ({
    isMore: false,
    videoId: "",
    swiperLargeOption: {
      direction: "vertical",
      slidesPerView: 3,
      spaceBetween: 0,
      slidesPerGroup: 1,
      navigation: {
        nextEl: ".swiper-button-next-about-us",
        prevEl: ".swiper-button-prev-about-us",
      },
    },
    swiperOption: {
      direction: "vertical",
      slidesPerView: 3,
      spaceBetween: 0,
      slidesPerGroup: 1,
      navigation: {
        nextEl: ".swiper-button-next-about-us",
        prevEl: ".swiper-button-prev-about-us",
      },
    },
    swiperMobileOption: {
      slidesPerView: 1,
      spaceBetween: 30,
      slidesPerGroup: 1,
      navigation: {
        nextEl: ".swiper-button-next-about-us",
        prevEl: ".swiper-button-prev-about-us",
      },
    },
    showImageBlock: false,
    key: 0,
    videoTitle: "Назва відео",
  }),
  props: {
    content: {
      require: true,
    },
    youtubeVideoLink: {
      require: false,
    },
  },
  mounted() {
    this.setVideoId();
    this.setShowImage();
  },
  methods: {
    setVideoId() {
      if (this.youtubeVideoLink) {
        this.videoId = this.$youtube.getIdFromUrl(
          this.youtubeVideoLink[0]?.content
        );
        console.log(this.videoId)
      }
    },
    setShowImage() {
      if (this.content.length > 0 && this.content[0]?.images?.length) {
        setTimeout(() => {
          this.showImageBlock = true;
        }, 2000);
      }
    },
  },
  watch: {
    youtubeVideoLink: {
      handler() {
        this.setVideoId();
      },
    },
    content: {
      deep: true,
      handler() {
        this.setShowImage();
      },
    },
  },
};
</script>

<style scoped>
@media only screen and (max-width: 1023px) {
  .aboutUsTitle {
    color: #eee;
    font-family: Roboto;
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    text-transform: uppercase;
  }
  .aboutUsText {
    color: #b5b5b5;
    font-family: Roboto;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
  .aboutUsSubtitle {
    color: #eee;
    font-family: Roboto;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
}
@media only screen and (min-width: 1024px) {
  .aboutUsTitle {
    color: #eee;
    font-family: Roboto;
    font-size: 36px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    text-transform: uppercase;
  }
  .aboutUsText {
    color: #b5b5b5;
    font-family: Roboto;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
  .aboutUsSubtitle {
    color: #eee;
    font-family: Roboto;
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
}
.contactUsBtn {
  border-radius: 4px;
  background: #085895 !important;
  box-shadow: 0px 2px 10px 0px rgba(60, 60, 60, 0.4);
  display: flex;
  height: 48px;
  width: 300px;
  padding: 11px 11px 11px 10px;
  justify-content: center;
  align-items: center;
  text-transform: none;
  color: #eee;
  text-align: center;
  font-family: Roboto;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.mobileTextDescription {
  font-size: 14px;
  line-height: 16px;
  text-align: justify;
  letter-spacing: 0.1em;
  color: #243949;
}
.mobileTextDescriptionOverflow {
  font-size: 14px;
  line-height: 16px;
  text-align: justify;
  letter-spacing: 0.1em;
  color: #243949;
  overflow: hidden;
  max-height: 140px;
  -webkit-box-orient: vertical;
  display: block;
  display: -webkit-box;
  overflow: hidden !important;
  text-overflow: ellipsis;
  -webkit-line-clamp: 8;
}
.otherTextDescription {
  font-size: 16px;
  color: #243949;
  text-align: justify;
}
.mobileLogo {
  position: absolute;
  width: 305px;
  height: 200px;
  padding-left: 10%;
  z-index: 0;
}
</style>