<template>
  <div class="reviewBackground">
    <success-snackbar v-if="showSuccessSnackbar" :snackbarText="snackbarText" />
    <v-main v-if="!$vuetify.breakpoint.smAndDown">
      <v-container>
        <v-row no-gutters justify="center">
          <p class="reviewSectionTitle">відгуки наших пасажирів</p>
        </v-row>
        <div
          style="position: absolute; margin-top: 100px"
          :style="$vuetify.breakpoint.xl ? 'width: 91svw' : 'width: 80svw;'"
        >
          <v-row no-gutters>
            <div
              class="swiperBtnBackground swiper-review-button-prev"
              :style="activeSlide == 1 ? 'pointer-events:none;' : ''"
              @click="activeSlide--"
              id="prev-btn"
            >
              <img
                src="../../../../assets/img/iconsSvg/nextReviewIcon.svg"
                alt="next slide"
              />
            </div>
            <v-spacer />
            <div
              class="swiperBtnBackground swiper-review-button-next"
              :style="
                activeSlide >= reviews.length ? 'pointer-events:none;' : ''
              "
              @click="activeSlide++"
            >
              <img
                src="../../../../assets/img/iconsSvg/nextReviewIcon.svg"
                alt="next slide"
                style="transform: rotate(0.5turn)"
              />
            </div>
          </v-row>
        </div>
        <v-col
          cols="7"
          md="12"
          lg="12"
          xl="12"
          v-show="swiperInitialized"
          :key="key"
        >
          <swiper
            v-if="$vuetify.breakpoint.xl"
            :style="{
              '--swiper-navigation-color': '#000000',
            }"
            :options="swiperLargeOption"
            ref="mySwiper"
          >
            <swiper-slide
              class="mainSwiper"
              v-for="review in reviews"
              :key="review.id"
              style="width: 10px"
            >
              <review-card :review="review" />
            </swiper-slide>
          </swiper>
          <swiper
            v-if="$vuetify.breakpoint.lg"
            :style="{
              '--swiper-navigation-color': '#000000',
            }"
            :options="swiperOption"
            ref="mySwiper"
            :updateOnWindowResize="true"
          >
            <swiper-slide
              class="mainSwiper"
              v-for="review in reviews"
              :key="review.id"
              style="width: 250px"
            >
              <review-card style="width: 250px" :review="review" />
            </swiper-slide>
          </swiper>
          <swiper
            v-if="$vuetify.breakpoint.md"
            :style="{
              '--swiper-navigation-color': '#000000',
            }"
            :options="swiperMediumOption"
          >
            <swiper-slide
              class="mainSwiper"
              v-for="review in reviews"
              :key="review.id"
              ref="mySwiper"
              style="width: 50px"
            >
              <review-card style="width: 250px" :review="review" />
            </swiper-slide>
          </swiper>
        </v-col>
        <v-row no-gutters justify="center" style="margin-top: 64px">
          <p class="createReviewBtn" @click="showCreateReviewModal = true">
            Залишити відгук
          </p>
        </v-row>
      </v-container>
    </v-main>
    <v-container v-else>
      <v-row no-gutters justify="center">
        <p class="reviewSectionTitle" style="margin-bottom: 40px">
          відгуки наших пасажирів
        </p>
      </v-row>
      <div
        style="width: 100svw; position: absolute; margin-top: 100px; left: 0"
      >
        <v-row no-gutters align="center">
          <div
            class="swiperBtnBackground swiper-review-button-prev"
            :style="activeSlide == 1 ? 'pointer-events:none;' : ''"
            @click="activeSlide--"
            id="prev-btn"
          >
            <img
              src="../../../../assets/img/iconsSvg/nextReviewIcon.svg"
              alt="prev slide"
              width="16px"
              height="42px"
            />
          </div>
          <v-spacer />
          <div
            class="swiperBtnBackground swiper-review-button-next"
            :style="activeSlide >= reviews.length ? 'pointer-events:none;' : ''"
            @click="activeSlide++"
          >
            <img
              src="../../../../assets/img/iconsSvg/nextReviewIcon.svg"
              alt="next slide"
              style="transform: rotate(0.5turn)"
              width="16px"
              height="42px"
            />
          </div>
        </v-row>
      </div>
      <v-col
        cols="12"
        md="12"
        lg="12"
        xl="12"
        v-show="swiperInitialized"
        :key="key"
      >
        <swiper
          v-if="$vuetify.breakpoint.smAndDown"
          class="swiper d-block d-md-none"
          :style="{
            '--swiper-navigation-color': '#000000',
          }"
          ref="mySwiper"
          :options="swiperMobileOption"
        >
          <swiper-slide
            class="mainSwiper"
            v-for="review in reviews"
            :key="review.id"
          >
            <v-row no-gutters justify="center">
              <review-card :review="review" style="width: 80% !important" />
            </v-row>
          </swiper-slide>
        </swiper>
      </v-col>
      <v-row no-gutters justify="center" style="margin-top: 64px">
        <p class="createReviewBtn" @click="showCreateReviewModal = true">
          Залишити відгук
        </p>
      </v-row>
    </v-container>
    <create-review-modal
      v-if="showCreateReviewModal"
      :visible="showCreateReviewModal"
      @createReview="createReview"
      @close="showCreateReviewModal = false"
    />
  </div>
</template>

<script>
import swiperOptionsMixin from "@/mixins/swiperOptionsMixin";
import reviewCard from "@/components/UI/cards/reviewCard.vue";
import CreateReviewModal from "./createReviewModal.vue";
import reviewsService from "@/requests/admin/reviewsService";
import SuccessSnackbar from "@/components/UI/successSnackbar.vue";
export default {
  mixins: [swiperOptionsMixin],
  components: { reviewCard, CreateReviewModal, SuccessSnackbar },
  data: () => ({
    activeSlide: 1,
    reviews: [],
    showCreateReviewModal: false,
    showSuccessSnackbar: false,
    swiperLargeOption: {
      navigation: {
        nextEl: ".swiper-review-button-next",
        prevEl: ".swiper-review-button-prev",
      },
    },
    swiperOption: {
      centeredSlides: true,
      roundLengths: true,
      loop: false,
      loopAdditionalSlides: 30,
      navigation: {
        nextEl: ".swiper-review-button-next",
        prevEl: ".swiper-review-button-prev",
      },
    },
    swiperMediumOption: {
      navigation: {
        nextEl: ".swiper-review-button-next",
        prevEl: ".swiper-review-button-prev",
      },
    },
    swiperMobileOption: {
      centeredSlides: true,
      roundLengths: true,
      loop: false,
      slidesPerView: 1,
      loopAdditionalSlides: 30,
      navigation: {
        nextEl: ".swiper-review-button-next",
        prevEl: ".swiper-review-button-prev",
      },
    },
    swiperInitialized: false,
    snackbarText: "",
    key: 0,
  }),
  mounted() {
    this.$nextTick(() => {
      this.swiperInitialized = true;
      const mySwiper = this.$refs.mySwiper.$swiper;
      mySwiper.on("slideChange", () => {
        this.activeSlide = mySwiper.activeIndex + 1;
      });
      setTimeout(() => {
        const myElement = document.getElementById("prev-btn");
        if (myElement) {
          myElement.click();
          this.activeSlide = 1;
        }
      }, 1500);
    });
    this.getReviews();
  },
  methods: {
    async getReviews() {
      await reviewsService.getReviews().then((res) => {
        if (res.status == "Success") {
          this.reviews = res.data;
        }
      });
    },
    async createReview(form) {
      await reviewsService.createReview(form).then((res) => {
        if (res.status == "Success") {
          this.showCreateReviewModal = false;
          this.snackbarText = "Ваш відгук успішно надісланий";
          this.showSuccessSnackbar = true;
        }
      });
    },
  },
};
</script>

<style scoped>
@media only screen and (max-width: 1023px) {
  .reviewBackground {
    background: #122b3e;
    padding: 40px 0px;
    background-image: url("../../../../assets/img/reviewsBackground.png");
    background-size: contain;
  }
  .reviewSectionTitle {
    color: #eee;
    text-align: center;
    font-family: Roboto;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 3.2px;
    text-transform: uppercase;
    margin-bottom: 64px;
  }
  .swiperBtnBackground {
    border-radius: 5px;
    width: 26px;
    height: 52px;
    display: grid;
    place-items: center;
    cursor: pointer;
    z-index: 90;
  }
  .swiperBtnBackground:hover {
    background: rgba(255, 255, 255, 0.2);
  }
}
@media only screen and (min-width: 1024px) {
  .reviewBackground {
    background: #122b3e;
    padding: 64px;
    background-image: url("../../../../assets/img/reviewsBackground.png");
    background-size: contain;
  }
  .reviewSectionTitle {
    color: #eee;
    text-align: center;
    font-family: Roboto;
    font-size: 32px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: 3.2px;
    text-transform: uppercase;
    margin-bottom: 64px;
  }
  .swiperBtnBackground {
    border-radius: 5px;
    width: 40px;
    height: 90px;
    display: grid;
    place-items: center;
    cursor: pointer;
    z-index: 90;
  }
  .swiperBtnBackground:hover {
    background: rgba(255, 255, 255, 0.2);
  }
}
.createReviewBtn {
  color: #828282;
  text-align: center;
  font-family: Roboto;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-decoration-line: underline;
  cursor: pointer;
  user-select: none;
}
.createReviewBtn:hover {
  color: #ffd70d;
}
</style>
<style lang="scss">
.mainSwiper.swiper-slide {
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 200ms linear;
  transform: scale(0.9);
  opacity: 0.8;

  &.swiper-slide-active {
    transform: scale(1);
    width: max-content;
    opacity: 1;
  }
}
</style>