<template>
  <div class="irregularTransportationBackground">
    <v-main>
      <v-container>
        <v-row no-gutters align="center">
          <v-col cols="12" xl="2" lg="2" md="2" sm="12">
            <img
              src="@/assets/img/irregularMain.png"
              alt="нерегулярні перевезення"
              width="180px"
              height="180px"
              style="object-fit: cover"
              loading="lazy"
            />
          </v-col>
          <v-col
            class="px-0 py-0"
            cols="12"
            xl="4"
            lg="4"
            md="4"
            sm="12"
            :style="
              $vuetify.breakpoint.smAndDown
                ? ''
                : 'text-align: left; padding-left: 20px'
            "
          >
            <h2 class="irregularTransportationTitle">
              Нерегулярні перевезення
            </h2>
            <p class="irregularTransportationText">
              Наші нерегулярні перевезення - це унікальний вибір для тих, хто
              шукає індивідуальний підхід у міжнародних перевезеннях.
            </p>
          </v-col>
          <v-col cols="12" xl="6" lg="6" md="6" sm="12">
            <v-row no-gutters align="center">
              <admin-form-button
                style="
                  font-size: 16px;
                  box-shadow: 0px 2px 10px 0px rgba(60, 60, 60, 0.4);
                "
                :style="$vuetify.breakpoint.smAndDown ? 'width: 100%;margin-top: 32px;' : ' margin-left: 40px;'"
                @click="$router.push('/irregular_transportation?toForm=true')"
                >Замовити перевезення</admin-form-button
              >
              <v-btn
                class="detailBtn"
                @click="$router.push('/irregular_transportation')"
                >Детальніше</v-btn
              >
            </v-row>
          </v-col>
        </v-row>
      </v-container>
    </v-main>
    <!-- <v-row justify="center" no-gutters>
      <v-col style="text-align: justify; padding-top: 50px">
        <p class="mainTitle mb-10">Нерегулярні перевезення</p>
        <v-container>
          <v-row justify="center" align="center">
            <div
              class="irregularTransportationBorderTopLeft"
              :style="
                $vuetify.breakpoint.xs
                  ? 'margin-right: 75%; margin-bottom: 720px'
                  : $vuetify.breakpoint.sm
                  ? 'margin-right: 87%; margin-bottom: 180px'
                  : 'margin-right: 80%; margin-bottom: 350px'
              "
            ></div>
            <v-col cols="10" xl="5" lg="5" md="5" sm="6" class="mr-10">
              <p style="color: #243949; font-size: 24px" v-if="content.length">
                {{ content[0].title }}
              </p>
              <p style="color: #243949; font-size: 20px" v-if="content.length">
                {{ content[0].content }}
              </p>
              <v-row
                :justify="$vuetify.breakpoint.xs ? 'center' : 'start'"
                no-gutters
              >
                <v-btn
                  :outlined="!isHover"
                  @mousemove="isHover = true"
                  @mouseleave="isHover = false"
                  :class="!isHover ? '' : 'white--text'"
                  @click="$router.push('/irregular_transportation')"
                  color="#085895"
                  width="144px"
                  height="34px"
                  >Детальніше</v-btn
                >
              </v-row>
            </v-col>
            <div v-if="content.length">
              <img
                v-if="content[0].images[0].images"
                :src="content[0].images[0].images.path"
                :width="
                  $vuetify.breakpoint.xs
                    ? '280px'
                    : $vuetify.breakpoint.sm
                    ? '300px'
                    : '600px'
                "
                :height="
                  $vuetify.breakpoint.xs
                    ? '140px'
                    : $vuetify.breakpoint.sm
                    ? '200px'
                    : '400px'
                "
                style="object-fit: cover"
                alt="фон"
              />
            </div>
            <div
              class="irregularTransportationBorderBottomRight"
              :style="
                $vuetify.breakpoint.xs
                  ? 'margin-left: 75%; margin-top: 760px'
                  : $vuetify.breakpoint.sm
                  ? 'margin-left: 90%; margin-top: 160px!important'
                  : 'margin-left: 82%; margin-top: 380px'
              "
            ></div>
          </v-row>
        </v-container>
      </v-col>
    </v-row> -->
  </div>
</template>

<script>
import adminFormButton from "@/components/UI/buttons/adminFormButton.vue";
export default {
  components: { adminFormButton },
  data: () => ({
    isHover: false,
  }),
  props: {
    content: {
      require: true,
    },
  },
};
</script>

<style scoped>
@media only screen and (max-width: 1023px) {
  .irregularTransportationBackground {
    padding: 32px 0px;
    background: #f1f2f6;
  }
  .irregularTransportationTitle {
    color: #1b1b1b;
    font-family: Roboto;
    font-size: 24px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    margin-bottom: 12px;
  }
  .irregularTransportationText {
    color: #1b1b1b;
    font-family: Roboto;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin-bottom: 0px;
  }
  .detailBtn {
    border-radius: 5px;
    border: 1px solid #e1e1e1;
    height: 40px !important;
    width: 100%;
    padding: 8px 12px;
    display: grid;
    place-items: center;
    color: #1b1b1b;
    font-family: Roboto;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin-top: 20px;
  }
}
@media only screen and (min-width: 1024px) {
  .irregularTransportationBackground {
    padding: 50px 0px;
    background: #f1f2f6;
  }
  .irregularTransportationTitle {
    color: #1b1b1b;
    font-family: Roboto;
    font-size: 32px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    margin-bottom: 12px;
  }
  .irregularTransportationText {
    color: #1b1b1b;
    font-family: Roboto;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin-bottom: 0px;
  }
  .detailBtn {
    border-radius: 5px;
    border: 1px solid #e1e1e1;
    height: 40px !important;
    min-width: 200px;
    max-width: 240px;
    width: 100%;
    padding: 8px 12px;
    display: grid;
    place-items: center;
    color: #1b1b1b;
    font-family: Roboto;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin-left: 20px;
  }
}
</style>