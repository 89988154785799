<template>
  <v-main>
    <v-container>
      <h1 class="aboutUsTitle">Чому саме ми?</h1>
      <v-row
        style="text-align: left; margin-top: 24px;"
        no-gutters
        align="start"
        justify="space-around"
      >
        <v-col
          cols="12"
          xl="3"
          lg="4"
          md="3"
          sm="12"
          :class="$vuetify.breakpoint.smAndDown ? '' : ''"
          style="text-align: center"
          :style="$vuetify.breakpoint.smAndDown ? 'padding: 24px' : 'padding: 24px 44px 24px 24px;'"
        >
          <img
            width="120px"
            height="120px"
            src="@/assets/img/dispatcherIcon.png"
            style="margin-bottom: 20px; "
            alt="диспетчер"
          />
          <h2 class="aboutUsTextTitle">Служба підтримки 24/7</h2>
          <span class="aboutUsText"
            >Ми готові проконсультувати Вас в будь-який час доби</span
          >
        </v-col>
        <v-col
          cols="12"
          xl="3"
          lg="4"
          md="3"
          sm="12"
          :class="$vuetify.breakpoint.smAndDown ? '' : ''"
          style="text-align: center"
          :style="$vuetify.breakpoint.smAndDown ? 'padding: 24px;' : 'padding: 24px'"
        >
          <img
            width="120px"
            height="120px"
            src="@/assets/img/qualityIcon.png"
            alt="сервіс"
            style="margin-bottom: 20px;"
          />
          <h2 class="aboutUsTextTitle">Якісний сервіс</h2>
          <span class="aboutUsText"
            >Ми завжди готові доступно та швидко забезпечити Вам комфортну
            подорож з надійним перевізником</span
          >
        </v-col>
        <v-col
          cols="12"
          xl="3"
          lg="4"
          md="3"
          sm="12"
          :class="$vuetify.breakpoint.smAndDown ? '' : ''"
          style="text-align: center"
          :style="$vuetify.breakpoint.smAndDown ? 'padding: 24px' : 'padding: 24px 44px 24px 24px;'"
        >
          <img
            width="120px"
            height="120px"
            src="@/assets/img/comfortIconMain.png"
            alt="комфортна подорож"
            style="margin-bottom: 20px;"
          />
          <h2 class="aboutUsTextTitle">Комфортна подорож</h2>
          <span class="aboutUsText"
            >Наші автобуси оснащені всім необхідним для Вашої зручної
            подорожі</span
          >
        </v-col>
      </v-row>
    </v-container>
  </v-main>
</template>

<script>
export default {};
</script>

<style scoped>
.aboutUsTitle {
  color: #1b1b1b;
  text-align: center;
  font-family: Roboto;
  font-size: 32px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  margin-top: 50px;
  margin-bottom: 24px;
}
.line {
  width: 80px;
  height: 0px;
  border: 2px solid #085895;
  margin-bottom: 24px;
}
.aboutUsTextTitle {
  color: #1b1b1b;
  text-align: center;
  font-family: Roboto;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  letter-spacing: 1.6px;
  margin: 0px 0px 12px 0px;
}
.aboutUsText {
  color: #4c5d6c;
  text-align: center;
  font-family: Roboto;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 1.4px;
}
</style>