import { render, staticRenderFns } from "./aboutUsDescription.vue?vue&type=template&id=16caeef6&scoped=true"
import script from "./aboutUsDescription.vue?vue&type=script&lang=js"
export * from "./aboutUsDescription.vue?vue&type=script&lang=js"
import style0 from "./aboutUsDescription.vue?vue&type=style&index=0&id=16caeef6&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "16caeef6",
  null
  
)

export default component.exports