import { Swiper, SwiperSlide } from "vue-awesome-swiper";
import "swiper/css/swiper.css";
export default {
    name: "swiper-example-loop-group",
    title: "Loop mode with multiple slides per group",
    components: {
        Swiper,
        SwiperSlide,
    },
    data: () => ({
        swiperLargeOption: {
            slidesPerView: 4,
            spaceBetween: 20,
            slidesPerGroup: 1,
            loop: false,
            loopFillGroupWithBlank: false,
            lazy: true,
            initialSlide: 0,
            pagination: {
                el: ".swiper-pagination",
                clickable: true,
            },
            navigation: {
                nextEl: ".swiper-button-next",
                prevEl: ".swiper-button-prev",
            },
        },
        swiperOption: {
            slidesPerView: 3,
            spaceBetween: 10,
            slidesPerGroup: 1,
            loop: false,
            loopFillGroupWithBlank: false,
            lazy: true,
            initialSlide: 0,
            pagination: {
                el: ".swiper-pagination",
                clickable: true,
            },
            navigation: {
                nextEl: ".swiper-button-next",
                prevEl: ".swiper-button-prev",
            },
        },
        swiperMediumOption: {
            slidesPerView: 2,
            spaceBetween: 0,
            slidesPerGroup: 2,
            initialSlide: 0,
            loop: false,
            loopFillGroupWithBlank: false,
            pagination: {
                el: ".swiper-pagination",
                clickable: true,
            },
            navigation: {
                nextEl: ".swiper-button-next",
                prevEl: ".swiper-button-prev",
            },
        },
        swiperMobileOption: {
            slidesPerView: 2,
            spaceBetween: 60,
            slidesPerGroup: 1,
            loop: false,
            loopFillGroupWithBlank: false,
            initialSlide: 0,
            pagination: {
                el: ".swiper-pagination",
                clickable: true,
            },
            navigation: {
                nextEl: ".swiper-button-next",
                prevEl: ".swiper-button-prev",
            },
        },
    })
}