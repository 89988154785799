import requestService from "../requestService";
import store from '@/store';

export default {
	async createReview(form) {
		const response = await requestService.post(`/feedback`, form)
		return response?.data
	},
	async updateReview(uuid, form) {
		const response = await requestService.post(`/admin/feedback/${uuid}`, form, {
			headers: { Authorization: `Bearer ${store.getters.loggedUser.token}` }
		})
		return response?.data
	},
	async getReviews() {
		const response = await requestService.get(`/feedback`)
		return response?.data
	},
	async getReviewsForAdmin(page) {
		const response = await requestService.get(`/admin/feedback/is_published/No?page=${page}`, {
			headers: { Authorization: `Bearer ${store.getters.loggedUser.token}` }
		})
		return response?.data
	},
	async getReview(id) {
		const response = await requestService.get(`/feedback/${id}`,)
		return response?.data
	},
	async addReviewToMain(id) {
		const response = await requestService.post(`/admin/feedback/publish/${id}`,{},
			{
				headers: {
					Authorization: `Bearer ${store.getters.loggedUser.token}`
				}
			})
		return response?.data
	},
	async deleteReview(id) {
		const response = await requestService.delete(`/admin/feedback/${id}`, {
			headers: { Authorization: `Bearer ${store.getters.loggedUser.token}` }
		})
		return response?.data
	},
}