<template>
  <v-dialog v-model="visibility" width="550px">
    <v-card class="createReviewModalBackground">
      <div
        class="closeIcon pointer"
        @click="$emit('close')"
        style="position: absolute; right: 20px"
      />
      <p
        style="
          color: #243949;
          text-align: center;
          font-family: Roboto;
          font-size: 36px;
          font-style: normal;
          font-weight: 500;
          line-height: normal;
        "
      >
        Залишити відгук
      </p>
      <v-col v-if="!loggedUser">
        <p class="reviewText">Увійдіть, щоб залишити відгук</p>
        <div
          class="typeUserBlock"
          style="margin-top: 32px"
          @click="showSignUpModal = true"
        >
          Я новий користувач
          <v-row no-gutters justify="end">
            <v-icon color="#1b1b1b" size="30px">mdi-chevron-right</v-icon>
          </v-row>
        </div>
        <div class="typeUserBlock" @click="showSignInModal = true">
          У мене вже є акаунт
          <v-row no-gutters justify="end">
            <v-icon color="#1b1b1b" size="30px">mdi-chevron-right</v-icon>
          </v-row>
        </div>
        <v-row no-gutters align="center">
          <v-col>
            <v-divider class="mt-5 mb-5 mr-5 px-15 divider" />
          </v-col>
          <p class="mt-4">або</p>
          <v-col> <v-divider class="mt-5 mb-5 ml-5 px-15 divider" /> </v-col
        ></v-row>
        <social-btn style="margin-top: 20px" />
      </v-col>
      <v-col v-else>
        <p
          style="
            color: #1c1c1c;
            text-align: center;
            font-family: Roboto;
            font-size: 20px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
            margin-bottom: 0px;
          "
        >
          Заповніть дані, щоб залишити відгук
        </p>
        <v-text-field
          dense
          outlined
          v-model="review.route"
          style="border-radius: 5px; margin-top: 40px"
          label="Маршрут (необов’язково)"
          placeholder="Введіть тут.."
          height="50px"
        />
        <v-textarea
          dense
          outlined
          v-model="review.message"
          style="border-radius: 5px"
          label="Ваш відгук"
          placeholder="Введіть тут.."
          counter="500"
          height="100px"
        ></v-textarea>
        <p
          style="
            color: #243949;
            font-family: Roboto;
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
            margin-bottom: 20px;
            margin-top: 24px;
          "
        >
          Оцініть якість перевізника:
        </p>
        <v-rating
          length="5"
          color="#FFD70D"
          background-color="#FFD70D"
          size="48px"
          v-model="review.rating"
        ></v-rating>
        <v-btn
          height="50px"
          width="100%"
          class="confirmBtn"
          @click="createReview"
        >
          Підтвердити</v-btn
        >
        <p class="cancelBtn" @click="$emit('close')">Скасувати</p>
      </v-col>
    </v-card>
    <sign-in
      v-if="showSignInModal"
      :visible="showSignInModal"
      @close="showSignInModal = false"
    />
    <sign-up-modal
      v-if="showSignUpModal"
      :visible="showSignUpModal"
      @close="showSignUpModal = false"
    />
  </v-dialog>
</template>

<script>
import modalMixin from "@/mixins/modalMixin";
import { mapGetters } from "vuex";
import socialBtn from "@/components/UI/buttons/socialBtn.vue";
import SignIn from "@/components/forAdmin/Auth/desktop/signIn.vue";
import SignUpModal from "@/components/forAdmin/Auth/desktop/signUpModal.vue";
import { validationMixin } from "vuelidate";
import { required } from "vuelidate/lib/validators";
export default {
  components: { socialBtn, SignIn, SignUpModal },
  mixins: [modalMixin, validationMixin],
  data: () => ({
    review: {},
    showSignInModal: false,
    showSignUpModal: false,
  }),
  validations: {
    review: {
      rating: {
        required,
      },
      message: {
        required,
      },
    },
  },
  computed: {
    ...mapGetters(["loggedUser"]),
  },
  methods: {
    createReview() {
      this.$v.$touch();
      if (!this.$v.$invalid) {
        let form = new FormData();
        if (this.review.route) {
          form.append("route", this.review.route);
        }
        form.append(
          "name",
          `${this.loggedUser.first_name} ${this.loggedUser.last_name}`
        );
        form.append("rating", this.review.rating);
        form.append("message", this.review.message);
        this.$emit("createReview", form);
      }
    },
  },
};
</script>

<style scoped>
.createReviewModalBackground {
  border-radius: 10px;
  background: #fcfcfc;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.1);
  padding: 40px 64px;
}
.reviewBackground {
  color: #1b1b1b;
  text-align: center;
  font-family: Roboto;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-top: 12px;
  margin-bottom: 0px;
}
.typeUserBlock {
  display: flex;
  height: 50px;
  max-width: 400px;
  padding: 5px 21px;
  align-items: center;
  border-radius: 4px;
  background: #fcfcfc;
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.15);
  color: #1b1b1b;
  font-family: Roboto;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  margin-bottom: 20px;
  cursor: pointer;
  user-select: none;
}
.confirmBtn {
  border-radius: 5px;
  text-transform: none;
  margin-top: 40px;
  color: #fff;
  font-family: Roboto;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 2px;
  background: #085895 !important;
}
.confirmBtn:hover {
  background: #09426d !important;
}
.cancelBtn {
  text-align: center;
  font-family: Roboto;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  text-decoration-line: underline;
  color: #828282;
  cursor: pointer;
  user-select: none;
  margin-top: 24px;
}
.cancelBtn:hover {
  color: #085895;
}
.closeIcon {
  width: 32px;
  height: 32px;
  background-color: #828282;
  -webkit-mask: url("../../../../assets/img/closeIcon.svg") no-repeat center;
  mask: url("../../../../assets/img/closeIcon.svg") no-repeat center;
}
.closeIcon:hover {
  background-color: #1b1b1b;
}
</style>