<template>
  <div class="reviewCardBackground">
    <v-row no-gutters justify="end" style="margin-bottom: 20px" v-if="isAdmin">
      <div class="deleteIcon" @click="$emit('deleteReviewFromMain', review.id)" />
    </v-row>
    <v-rating
      length="5"
      size="32"
      :value="review?.rating"
      color="#FFD70D"
      background-color="#E8E8E8"
      readonly
    ></v-rating>
    <p class="reviewCardRouteName" v-if="review?.route?.length > 0">
      {{ review.route }}
    </p>
    <p class="reviewCardText">
      {{ review.message }}
    </p>
    <v-row no-gutters justify="center">
      <div
        style="background: rgba(181, 181, 181, 0.5); width: 80px; height: 1px"
      />
    </v-row>
    <p class="reviewCardName">{{ review.name }}</p>
  </div>
</template>

<script>
export default {
  props: {
    review: {
      require: true,
    },
    showRating: {
      require: true,
    },
    isAdmin: {
      require: false,
    },
  },
};
</script>

<style scoped>
.reviewCardBackground {
  border-radius: 10px;
  background: #fff;
  box-shadow: 0px 0px 10px 0px rgba(53, 53, 53, 0.15);
  padding: 40px;
  text-align: center;
  width: max-content;
  height: max-content;
  width: 250px !important;
  margin: 0px 20px;
  transition: all 0.3s ease;
}
.reviewCardRouteName {
  color: #085895;
  text-align: center;
  font-family: Roboto;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  margin-bottom: 12px;
  margin-top: 32px;
}
.reviewCardText {
  color: #1c1c1c;
  text-align: center;
  font-family: Roboto;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-bottom: 12px;
}
.reviewCardName {
  color: #243949;
  text-align: center;
  font-family: Roboto;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  margin-top: 32px;
}
.deleteIcon {
  width: 16px;
  height: 20px;
  background-color: #828282;
  -webkit-mask: url("../../../assets/img/deleteIcon.svg") no-repeat center;
  mask: url("../../../assets/img/deleteIcon.svg") no-repeat center;
  cursor: pointer;
  position: absolute;
  right: 40px;
}
.deleteIcon:hover {
  background-color: #ca0000;
}
</style>